/*
| Developed by Dirupt
| Filename : SMenuUserIcon.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SMenuUserIconProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SMenuUserIcon: React.FC<SMenuUserIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="SMenuUserIcon" data-sentry-source-file="SMenuUserIcon.tsx">
			<svg viewBox="0 0 31 32" data-sentry-element="svg" data-sentry-source-file="SMenuUserIcon.tsx">
				<g clipPath="url(#clip0_857_20713)" data-sentry-element="g" data-sentry-source-file="SMenuUserIcon.tsx">
					<path fillRule="evenodd" clipRule="evenodd" d="M18.9669 29.4703C20.7316 25.8194 22.217 24.0668 21.5047 19.6307C21.1728 17.5665 21.7516 16.9148 22.5975 16.0122C23.4273 15.1217 25.1434 15.2229 25.0463 13.6889C25.0018 12.9725 24.5525 12.4382 23.9211 12.1427C23.5285 11.9606 23.5365 11.4385 23.6782 11.1147C23.7511 10.9447 23.5244 10.6654 23.3544 10.5925C23.0751 10.467 22.7716 10.5682 22.7716 10.5682C22.8768 10.3659 22.9375 10.1635 22.9254 9.94087C22.9092 9.68992 22.7837 9.43087 22.3587 9.51182C22.0754 9.56444 21.9094 9.11921 21.8001 8.92493C21.6666 8.68612 21.8932 8.35018 21.9459 8.10732C22.0754 7.54876 22.1685 6.58949 21.4521 6.58139C19.5173 6.56115 18.0278 6.01878 17.619 5.77188C17.1697 5.50069 16.9633 4.92999 16.6193 4.55357C15.7086 3.55382 14.6076 3.02764 12.8105 2.40026C14.2069 -0.275173 19.8614 -1.02802 22.5125 1.77289C23.6337 2.95478 24.6496 3.50929 25.2851 4.9138C25.6129 5.63831 25.4713 6.30616 25.706 6.59758C26.0056 6.96996 27.2401 7.61352 29.0291 8.66993C29.701 8.92088 29.2841 9.79111 28.9724 10.2728C28.8348 10.4832 28.511 10.722 28.5555 10.9892C28.5919 11.2078 28.596 11.6854 28.8793 11.7299C29.3084 11.7987 29.3367 12.082 29.2679 12.3249C29.2031 12.5394 29.0736 12.7094 28.9077 12.8632C28.9077 12.8632 29.2274 12.8753 29.45 13.0899C29.5836 13.2153 29.701 13.5553 29.5755 13.6889C29.3327 13.9439 29.1505 14.4337 29.4541 14.7372C29.9479 15.231 30.1867 15.8867 29.9843 16.5748C29.5512 18.0481 27.9727 17.3681 26.8879 17.9227C25.7829 18.4853 25.6008 18.9993 25.2082 21.0514C24.5687 24.3785 21.8811 27.7299 18.9749 29.4663L18.9669 29.4703Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuUserIcon.tsx" />
					<path d="M18.2383 19.7925C18.21 19.2056 18.1493 18.6228 18.0562 18.044C17.3883 13.8669 15.1824 10.0055 13.3367 7.37462C11.831 5.23346 10.5641 3.90991 10.5641 3.90991V3.93824C10.8434 7.62557 10.1027 11.321 8.41487 14.6036C7.98583 10.4589 6.69466 8.92889 6.69466 8.92889C6.69466 8.92889 7.02656 12.1346 5.83658 13.9479C1.96711 19.8371 4.54541 22.897 4.54541 22.897C1.3195 22.897 1.53402 17.2223 1.53402 17.2223C1.53402 17.2223 -3.62661 26.1715 4.97445 31.1864C5.58968 31.5466 6.31014 31.8219 7.06704 32C7.05489 31.9959 7.0468 31.9919 7.03466 31.9838C6.89704 31.9474 6.77156 31.8866 6.66228 31.8016C6.45585 31.6883 6.26562 31.5588 6.09157 31.409C5.36706 30.7817 5.01088 29.8021 4.92992 28.8388C4.84897 27.8755 5.01897 26.9081 5.18492 25.961C5.17683 27.1024 5.64634 28.2155 6.39919 29.0574C7.05085 29.786 7.90893 30.3202 8.86415 30.5024C8.02631 29.3569 7.24918 28.0738 7.08323 26.6734C7.06299 26.4993 7.05085 26.3212 7.05085 26.1432C7.05085 24.9086 7.55679 23.7308 8.13964 22.6501C9.00582 21.0473 10.0541 19.5537 10.9163 17.9428C11.2603 17.2992 11.8755 16.2185 12.2358 15.1986C12.4584 14.5752 12.5839 13.9721 12.4948 13.5026C12.6324 14.2231 12.6365 14.9476 12.5636 15.664C12.3653 17.5502 11.5801 19.404 10.9486 21.1363C10.8394 21.4318 10.7382 21.7354 10.641 22.0389C9.98533 24.1153 9.65747 26.386 10.6815 28.26C11.7339 30.1867 13.1181 26.3253 13.2922 25.6089C13.5472 24.5808 13.7131 23.5082 13.7819 22.4477C13.7819 22.4477 15.1136 27.8553 14.3769 29.7657C17.4288 26.6289 18.3962 23.1439 18.2342 19.7763L18.2383 19.7925Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuUserIcon.tsx" />
				</g>
				<defs data-sentry-element="defs" data-sentry-source-file="SMenuUserIcon.tsx">
					<clipPath id="clip0_857_20713" data-sentry-element="clipPath" data-sentry-source-file="SMenuUserIcon.tsx">
						<rect width="30.0491" height="32" fill="currentColor" data-sentry-element="rect" data-sentry-source-file="SMenuUserIcon.tsx" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>;
};