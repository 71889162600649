/*
| Developed by Dirupt
| Filename : MSubscription.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MSubscriptionProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MSubscription: React.FC<MSubscriptionProps> = props => {
  // Render
  //--------------------------------------------------------------------------
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MSubscription" data-sentry-source-file="MSubscription.tsx">
			<svg viewBox="0 0 24 24" data-sentry-element="svg" data-sentry-source-file="MSubscription.tsx">
				<path d="M16.2004 15.6C16.0413 15.6 15.8886 15.6632 15.7761 15.7757C15.6636 15.8883 15.6004 16.0409 15.6004 16.2C15.6004 16.3591 15.6636 16.5117 15.7761 16.6243C15.8886 16.7368 16.0413 16.8 16.2004 16.8H18.6004C18.7595 16.8 18.9121 16.7368 19.0247 16.6243C19.1372 16.5117 19.2004 16.3591 19.2004 16.2C19.2004 16.0409 19.1372 15.8883 19.0247 15.7757C18.9121 15.6632 18.7595 15.6 18.6004 15.6H16.2004ZM2.40039 8.1C2.40039 7.22479 2.74807 6.38542 3.36694 5.76655C3.98581 5.14768 4.82518 4.8 5.70039 4.8H18.3004C19.1756 4.8 20.015 5.14768 20.6338 5.76655C21.2527 6.38542 21.6004 7.22479 21.6004 8.1V15.9C21.6004 16.7752 21.2527 17.6146 20.6338 18.2335C20.015 18.8523 19.1756 19.2 18.3004 19.2H5.70039C4.82518 19.2 3.98581 18.8523 3.36694 18.2335C2.74807 17.6146 2.40039 16.7752 2.40039 15.9V8.1ZM5.70039 6C5.14344 6 4.60929 6.22125 4.21547 6.61508C3.82164 7.00891 3.60039 7.54305 3.60039 8.1V9.6H20.4004V8.1C20.4004 7.54305 20.1791 7.00891 19.7853 6.61508C19.3915 6.22125 18.8573 6 18.3004 6H5.70039ZM20.4004 10.8H3.60039V15.9C3.60039 17.0592 4.54119 18 5.70039 18H18.3004C18.8573 18 19.3915 17.7788 19.7853 17.3849C20.1791 16.9911 20.4004 16.457 20.4004 15.9V10.8Z" fill="currentColord" data-sentry-element="path" data-sentry-source-file="MSubscription.tsx" />
			</svg>
		</SvgIcon>;
};