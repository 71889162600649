/*
| Developed by Dirupt
| Filename : SwitchField.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, FormControl, FormControlLabelProps, FormHelperText, styled, Switch, SwitchProps, Tooltip, Typography } from '@mui/material';
import { useField } from 'formik';
import _ from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import Link from 'next/link';
import { FormControlLabel } from '@/components/formik';
import { BadgePremium } from '@/components/common/svgs/badge';
import { useMe } from '@/services/api/modules/user/hooks/useMe';
import { UrlsConfig } from '@/config/urls.config';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SwitchFieldProps extends Omit<SwitchProps, 'form' | 'name' | 'value' | 'checked' | 'defaultChecked'> {
  name: string;
  label?: string | React.ReactNode;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  formControlLabelProps?: Omit<FormControlLabelProps, 'label' | 'labelPlacement'>;
  helperText?: string;
  isPremium?: boolean;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
export const FormControlStyled = styled(FormControl)(({
  theme
}) => ({
  '& .MuiFormHelperText-root': {
    marginLeft: theme.spacing(6.375)
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SwitchField: React.FC<SwitchFieldProps> = ({
  name,
  label,
  labelPlacement = 'end',
  formControlLabelProps,
  helperText,
  isPremium = false,
  disabled = false,
  ...props
}) => {
  const {
    t
  } = useTranslation();
  const {
    data: authUser
  } = useMe();
  const [field, meta, helpers] = useField(name);
  const {
    error,
    showError
  } = React.useMemo(() => {
    const showError = meta.touched;
    const error = typeof meta.error === 'string' ? meta.error : _.get(meta.error, name);
    return {
      error,
      showError
    };
  }, [meta.error, meta.touched, name]);

  // Control
  // ----------------------------------------------------------------------------
  const switchControl = <Switch {...field} {...props} disabled={disabled || isPremium && !authUser?.isSubscriptionActive} type={'checkbox'} checked={field.value} onChange={(event, value) => {
    void helpers.setValue(value);
    if (props.onChange) {
      props.onChange(event, value);
    }
  }} />;

  // Render
  // ----------------------------------------------------------------------------
  return <FormControlStyled error={showError ? Boolean(error) : undefined} data-sentry-element="FormControlStyled" data-sentry-component="SwitchField" data-sentry-source-file="SwitchField.tsx">
			{label ? <Tooltip title={isPremium && !authUser?.isSubscriptionActive ? <Trans i18nKey={'common:common.thisFeatureIsPremium.tooltip'} components={[<Typography key={0} component={Link} href={UrlsConfig.account.subscription} color={'primary'} variant={'caption'} />]} /> : ''}>
					<div>
						<FormControlLabel control={switchControl} disabled={disabled || isPremium && !authUser?.isSubscriptionActive} label={isPremium ? <Box display={'flex'} alignItems={'center'} gap={0.5}>
										{label}
										<Tooltip title={t('common:common.premium')}>
											<BadgePremium sx={{
              fontSize: 'inherit'
            }} />
										</Tooltip>
									</Box> : label} labelPlacement={labelPlacement} {...formControlLabelProps} />
					</div>
				</Tooltip> : switchControl}
			{showError && error || helperText ? <FormHelperText>{error || helperText}</FormHelperText> : null}
		</FormControlStyled>;
};