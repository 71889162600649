/*
| Developed by Dirupt
| Filename : MosaicMenuNavCard.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Box, Card, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import Link from 'next/link';
import { m } from 'framer-motion';
import { useResponsive } from '@/hooks/useResponsive';
import { MOBILE_NAV_HEIGHT } from '@/components/layouts/app/DefaultLayout/MobileNav/MobileNav';
import { DESKTOP_NAV_HEIGHT } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopNav';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MosaicMenuNavCardProps {
  title: string;
  subtitle: string;
  icon: React.ReactNode;
  href: string;
  custom: number;
  onClose: () => void;
  backgroundImage?: string;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const MosaicMenuNavCardContent = styled(Card, {
  shouldForwardProp: prop => prop !== 'backgroundImage'
})<{
  backgroundImage?: string;
}>(({
  theme,
  backgroundImage
}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignSelf: 'stretch',
  gap: theme.spacing(2),
  padding: theme.spacing(1.5, 2),
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  cursor: 'pointer',
  transition: 'all 0.3s ease-in-out',
  textAlign: 'left',
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.neutral.white,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
    transition: 'background-color 0.3s ease-in-out',
    zIndex: 0
  },
  '&:hover': {
    borderColor: theme.palette.neutral.white,
    '&::before': {
      backgroundColor: 'rgba(0,0,0,0.3)'
    }
  }
}));
const IconWrapper = styled(Box)(({
  theme
}) => ({
  color: theme.palette.neutral.white,
  position: 'relative',
  zIndex: 1,
  '& svg': {
    width: 32,
    height: 32,
    fill: 'currentColor'
  }
}));
const Title = styled(Typography)(({
  theme
}) => ({
  ...theme.typography.overline,
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    ...theme.typography.subtitle1
  }
}));
const Description = styled(Typography)(({
  theme
}) => ({
  ...theme.typography.caption,
  [theme.breakpoints.up('md')]: {
    ...theme.typography.body2
  }
}));

/*
|--------------------------------------------------------------------------
| Variants
|--------------------------------------------------------------------------
*/
const cardVariants = {
  openMobile: (index: number) => ({
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 20,
      delay: 0.075 * (4 - index)
    }
  }),
  openDesktop: (index: number) => ({
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 20,
      delay: 0.075 * index
    }
  }),
  closedMobile: (index: number) => ({
    y: 50,
    opacity: 0,
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 20,
      delay: 0.075 * index
    }
  }),
  closedDesktop: (index: number) => ({
    y: -100,
    opacity: 0,
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 20,
      delay: 0.075 * (4 - index)
    }
  })
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MosaicMenuNavCard = (props: MosaicMenuNavCardProps) => {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');
  return <m.div variants={cardVariants} custom={props.custom} initial={isDesktop ? 'closedDesktop' : 'closedMobile'} animate={isDesktop ? 'openDesktop' : 'openMobile'} exit={isDesktop ? 'closedDesktop' : 'closedMobile'} style={{
    position: 'absolute',
    width: '100%',
    maxWidth: '450px',
    left: 0,
    padding: `0 ${theme.spacing(2)}`,
    ...(isDesktop ? {
      top: `${DESKTOP_NAV_HEIGHT / 2 + props.custom * 80}px`
    } : {
      bottom: `${MOBILE_NAV_HEIGHT + 40 + (4 - props.custom) * 80}px`
    })
  }} data-sentry-element="unknown" data-sentry-component="MosaicMenuNavCard" data-sentry-source-file="MosaicMenuNavCard.tsx">
			<Link href={props.href} style={{
      textDecoration: 'none'
    }} onClick={props.onClose} data-sentry-element="Link" data-sentry-source-file="MosaicMenuNavCard.tsx">
				<MosaicMenuNavCardContent backgroundImage={props.backgroundImage} data-sentry-element="MosaicMenuNavCardContent" data-sentry-source-file="MosaicMenuNavCard.tsx">
					<IconWrapper data-sentry-element="IconWrapper" data-sentry-source-file="MosaicMenuNavCard.tsx">{props.icon}</IconWrapper>
					<Box display="flex" flexDirection="column" gap={0} sx={{
          position: 'relative',
          zIndex: 1
        }} data-sentry-element="Box" data-sentry-source-file="MosaicMenuNavCard.tsx">
						<Title data-sentry-element="Title" data-sentry-source-file="MosaicMenuNavCard.tsx">{props.title}</Title>
						<Description data-sentry-element="Description" data-sentry-source-file="MosaicMenuNavCard.tsx">{props.subtitle}</Description>
					</Box>
				</MosaicMenuNavCardContent>
			</Link>
		</m.div>;
};