/*
| Developed by Dirupt
| Filename : UserNavItems.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { BlockOutlined, Campaign, Password, PeopleOutline, Policy, QrCode, SupervisorAccount } from '@mui/icons-material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { m } from 'framer-motion';
import { Box } from '@mui/material';
import { mutate } from 'swr';
import { AuthEndpoint } from '@/services/api/modules/auth/auth.endpoint';
import { UrlsConfig } from '@/config/urls.config';
import { MUserProfileFilters } from '@/components/common/svgs/icons/MUserProfileFilters';
import { MUserAlbums } from '@/components/common/svgs/icons/MUserAlbums';
import { MFlame } from '@/components/common/svgs/icons/MFlame';
import { MSubscription } from '@/components/common/svgs/icons/MSubscription';
import { MFAQ } from '@/components/common/svgs/icons/MFAQ';
import { UserNavSection, UserNavSectionProps } from '@/components/modules/user/menu/nav-items/UserNavSection';
import { variantFade } from '@/components/animate';
import { MDarkModeSwitch } from '@/components/common/MDarkModeSwitch';
import { MPaddedBox } from '@/components/common/MPaddedBox';
import { useMe } from '@/services/api/modules/user/hooks/useMe';
import { MPlaces } from '@/components/common/svgs/icons/MPlaces';
import { LangSwitch } from '@/components/common/LangSwitch';
import { FollowIcon } from '@/components/common/svgs/icons/FollowIcon';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UserNavItemsProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const UserNavItems: React.FC<UserNavItemsProps> = () => {
  const {
    data: authUser
  } = useMe();
  const {
    t
  } = useTranslation();
  const router = useRouter();

  // Methods
  // ----------------------------------------------------------------------------
  const handleLogout = React.useCallback(() => {
    AuthEndpoint.logout().then(async () => {
      await router.replace(UrlsConfig.auth.login);
      await mutate(() => true, undefined, {
        revalidate: false
      });
    }).catch(console.error);
  }, [router]);

  // Prepare nav items
  // ----------------------------------------------------------------------------
  const navItems = React.useMemo<Array<UserNavSectionProps>>(() => [{
    id: 'parameters',
    label: t('account/index:navList.parameters.label'),
    defaultExpanded: true,
    hideExpandIcon: true,
    subNav: {
      // accountPassword: {
      // 	type: 'link',
      // 	href: UrlsConfig.account.account,
      // 	label: t(`account/index:nav.accountPassword`),
      // 	icon: <MLock fill={'currentColor'} />,
      // 	badgeText: 'common:common.soonAvailable',
      // 	disabled: true,
      // },
      accountInformations: {
        type: 'link',
        href: UrlsConfig.account.accountInformations,
        label: t('account/index:navList.parameters.subNav.accountInformations'),
        icon: <AccountCircleOutlinedIcon />
      },
      password: {
        type: 'link',
        href: UrlsConfig.account.password,
        label: t('account/index:navList.parameters.subNav.password'),
        icon: <Password />
      },
      profils: {
        type: 'link',
        href: UrlsConfig.account.profile,
        label: t('account/index:navList.parameters.subNav.profils'),
        icon: <SupervisorAccount />
      },
      filters: {
        type: 'link',
        href: UrlsConfig.account.filters,
        label: t('account/index:navList.parameters.subNav.filters'),
        icon: <MUserProfileFilters fill={'currentColor'} />
      }
      // kyc: {
      // 	type: 'link',
      // 	href: UrlsConfig.account.kyc,
      // 	label: t('account/index:navList.parameters.subNav.kyc'),
      // 	icon: <MUserVerification fill={'currentColor'} />,
      // 	isPremiumFeature: 'premium',
      // 	// badgeText: 'common:common.new',
      // 	// badgeColor: 'primary',
      // },
    }
  }, {
    id: 'adminSpace',
    label: t('account/index:navList.adminSpace.label'),
    hideIf: !authUser?.isAdmin ?? true,
    defaultExpanded: true,
    hideExpandIcon: true,
    subNav: {
      redeemCodes: {
        type: 'link',
        href: UrlsConfig.admin.redeemCodes,
        label: t('account/index:navList.adminSpace.subNav.redeemCodes'),
        icon: <QrCode />
      },
      events: {
        type: 'link',
        href: UrlsConfig.event.list,
        // @ts-ignore
        activeHref: UrlsConfig.event._managementActiveLink,
        label: t('account/index:navList.commonSpace.subNav.events'),
        icon: <MPlaces />
      }
    }
  }, {
    id: 'ambassadorSpace',
    label: t('account/index:navList.ambassadorSpace.label'),
    hideIf: !authUser?.isAmbassador ?? true,
    defaultExpanded: true,
    hideExpandIcon: true,
    subNav: {
      myAmbassadorSpace: {
        type: 'link',
        href: UrlsConfig.ambassador.space,
        label: t('account/index:navList.ambassadorSpace.subNav.myAmbassadorSpace'),
        icon: <Campaign />
      },
      myCommunity: {
        type: 'link',
        href: UrlsConfig.ambassador.community,
        label: t('account/index:navList.ambassadorSpace.subNav.myCommunity'),
        icon: <PeopleOutline />
      },
      events: {
        type: 'link',
        href: UrlsConfig.event.list,
        // @ts-ignore
        activeHref: UrlsConfig.event._managementActiveLink,
        label: t('account/index:navList.commonSpace.subNav.events'),
        icon: <MPlaces />
      }
    }
  }, {
    id: 'partnerSpace',
    label: t('account/index:navList.partnerSpace.label'),
    hideIf: !authUser?.isPartner ?? true,
    defaultExpanded: true,
    hideExpandIcon: true,
    subNav: {
      myPartnerSpace: {
        type: 'link',
        href: UrlsConfig.partner.space,
        label: t('account/index:navList.partnerSpace.subNav.myPartnerSpace'),
        icon: <Campaign />
      },
      events: {
        type: 'link',
        href: UrlsConfig.event.list,
        // @ts-ignore
        activeHref: UrlsConfig.event._managementActiveLink,
        label: t('account/index:navList.partnerSpace.subNav.events'),
        icon: <MPlaces />
      }
    }
  }, {
    id: 'userSpace',
    label: t('account/index:navList.userSpace.label'),
    hideIf: !authUser?.isUser ?? true,
    defaultExpanded: true,
    hideExpandIcon: true,
    subNav: {
      events: {
        type: 'link',
        href: UrlsConfig.event.list,
        // @ts-ignore
        activeHref: UrlsConfig.event._managementActiveLink,
        label: t('account/index:navList.commonSpace.subNav.events'),
        icon: <MPlaces />,
        // @ts-ignore
        isPremiumFeature: 'premium'
      }
    }
  }, {
    id: 'features',
    label: t('account/index:navList.features.label'),
    defaultExpanded: true,
    hideExpandIcon: true,
    subNav: {
      crushes: {
        type: 'link',
        href: UrlsConfig.account.crushes,
        label: t(`account/index:navList.features.subNav.crushes`),
        icon: <FollowIcon fill={'currentColor'} />
      },
      mediaFolderRequests: {
        type: 'link',
        href: UrlsConfig.account.mediaFolderRequests,
        label: t(`account/index:navList.features.subNav.mediaFolderRequests`),
        icon: <MUserAlbums fill={'currentColor'} />
      },
      blacklist: {
        type: 'link',
        href: UrlsConfig.account.blacklist,
        label: t(`account/index:navList.features.subNav.blacklist`),
        icon: <BlockOutlined fill={'currentColor'} />
      },
      events: {
        type: 'link',
        href: `${UrlsConfig.profile.details(authUser?.id ?? '')}?tab=events`,
        label: t('account/index:navList.features.subNav.events'),
        icon: <MPlaces />
      },
      questions: {
        type: 'link',
        href: UrlsConfig.account.questions,
        label: t(`account/index:navList.features.subNav.questions`),
        icon: <MFlame fill={'currentColor'} />,
        // @ts-ignore
        isPremiumFeature: 'premium'
      }
      // settings: {
      // 	type: 'link',
      // 	href: UrlsConfig.profile.settings,
      // 	label: t(`account/index:navList.accessibility.subNav.settings`),
      // 	icon: <MSettings fill={'currentColor'} />,
      // },
    }
  }, {
    id: 'subscription',
    label: t('account/index:navList.subscription.label'),
    defaultExpanded: true,
    hideExpandIcon: true,
    subNav: {
      subscription: {
        type: 'link',
        href: UrlsConfig.account.subscription,
        label: t(`account/index:navList.subscription.subNav.subscription`),
        icon: <MSubscription fill={'currentColor'} />
      }
      // rewards: {
      // 	type: 'link',
      // 	href: '#',
      // 	label: t(`account/index:navList.subscription.subNav.rewards`),
      // 	icon: <LockOutlinedIcon />,
      // },
    }
  }, {
    id: 'legals',
    label: t('account/index:navList.legals.label'),
    subNav: {
      policy: {
        type: 'link',
        href: UrlsConfig.external.policy,
        label: t(`account/index:navList.legals.subNav.policy`),
        icon: <Policy />
      },
      cguCgv: {
        type: 'link',
        href: UrlsConfig.external.cguCgv,
        label: t(`account/index:navList.legals.subNav.cguCgv`),
        icon: <AutoStoriesIcon />
      },
      codeOfConduct: {
        type: 'link',
        href: UrlsConfig.external.codeOfConduct,
        label: t(`account/index:navList.legals.subNav.codeOfConduct`),
        icon: <ReceiptLongIcon />
      },
      faq: {
        type: 'link',
        href: UrlsConfig.faq,
        label: t(`account/index:navList.legals.subNav.faq`),
        icon: <MFAQ fill={'currentColor'} />
      }
    }
  }, {
    id: 'logout',
    label: t('account/index:navList.logout.label'),
    defaultExpanded: true,
    hideExpandIcon: true,
    subNav: {
      logout: {
        type: 'button',
        label: t('account/index:navList.logout.subNav.logout'),
        icon: <LogoutOutlinedIcon />,
        onClick: handleLogout
      }
    }
  }], [handleLogout, t, authUser]);

  // render
  // ----------------------------------------------------------------------------
  return <MPaddedBox data-sentry-element="MPaddedBox" data-sentry-component="UserNavItems" data-sentry-source-file="UserNavItems.tsx">
			{navItems.map(navItem =>
    // @ts-ignore
    // eslint-disable-next-line react/jsx-key
    <UserNavSection key={navItem.id} {...navItem} />)}

			<Box display={'flex'} justifyContent={'space-evenly'} gap={2} component={m.div} variants={variantFade().inLeft} marginTop={2} marginBottom={2} data-sentry-element="Box" data-sentry-source-file="UserNavItems.tsx">
				<MDarkModeSwitch data-sentry-element="MDarkModeSwitch" data-sentry-source-file="UserNavItems.tsx" />
				<LangSwitch data-sentry-element="LangSwitch" data-sentry-source-file="UserNavItems.tsx" />
			</Box>
		</MPaddedBox>;
};