/*
| Developed by Dirupt
| Filename : theme.palette.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { alpha, PaletteMode, ThemeOptions } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Theme Palette
|--------------------------------------------------------------------------
*/
export const getThemePalette = (mode: PaletteMode): ThemeOptions['palette'] => {
	if (mode === 'dark')
		return {
			mode,
			primary: {
				main: '#F51F56',
			},
			primaryExtra: {
				75: '#F2406E',
				50: '#EF6286',
				25: '#EB9CB0',
				10: '#E7C6CE',
			},
			secondary: {
				main: '#181e29',
				light: '#1A1A1A',
				dark: '#000000',
			},
			secondaryExtra: {
				75: '#151f33',
				50: '#1c283d',
				25: '#202f47',
				10: '#273857',
			},
			tertiary: {
				dark: '#2FACE2',
				main: '#32C997',
				light: '#72F324',
				contrastText: '#fff',
			},
			tertiaryExtra: {
				75: '#2DBD8A',
				50: '#32C997',
				25: '#37D4A5',
				10: '#3CE0B3',
			},
			neutral: {
				black: '#212121',
				darkGrey: '#4D4D4D',
				grey: '#717171',
				lightGrey: '#DCE2E6',
				silver: '#F5F7FA',
				white: '#FFFFFF',
			},
			background: {
				default: '#06080B',
				paper: '#181e29',
			},
			gender: {
				male: '#39D2C0',
				woman: '#F51F56',
				other: '#DCE2E6',
			},
			gradients: {
				background: 'linear-gradient(180deg, #020211 0%, #0B0B16 100%)',
				pinkLight:
					'linear-gradient(90deg, rgb(13 1 89 / 50%) 0%, rgb(165 0 123 / 50%) 50%, rgb(245 31 86 / 50%) 100%)',
				pink: 'linear-gradient(90deg, #0D0159 0%, #A5007B 50%, #F51F56 100%)',
				pinkOpacity:
					'linear-gradient(90deg, hsl(248.18deg 97.78% 17.65% / 20%) 0%, hsl(315.27deg 100% 32.35% / 20%) 50%, hsl(345deg 91% 54% / 20%) 100%)',
				lgbt: 'linear-gradient(90deg, #FF0018 0%, #FFA52C 19.79%, #FFFF41 38.54%, #008018 58.33%, #0000F9 79.17%, #86007D 100%)',
				tertiary: 'linear-gradient(90deg, #2FACE2 0.15%, #72F223 100%)',
				gold: 'linear-gradient(90deg, #FFD700 0%, #FFA500 50%, #FF8C00 100%)',
			},
			text: {
				primary: '#FFFFFF',
				secondary: '#F51F56',
				disabled: alpha('#FFFFFF', 0.5),
			},
			success: {
				dark: '#1B6E53',
				main: '#32C997',
				light: '#F1FBF8',
			},
			warning: {
				dark: '#FFA826',
				main: '#FFC670',
				light: '#FFF8EC',
				contrastText: '#000',
			},
			error: {
				dark: '#E01507',
				main: '#FF5A4F',
				light: '#FFF1F0',
			},
			info: {
				dark: '#0792E0',
				main: '#4FBEFF',
				light: '#F0F9FF',
			},
			divider: alpha('#fff', 0.22),
		}

	return {
		mode,
		primary: {
			main: '#F51F56',
		},
		primaryExtra: {
			75: '#F2406E',
			50: '#EF6286',
			25: '#EB9CB0',
			10: '#E7C6CE',
		},
		secondary: {
			main: '#000000',
			light: '#1A1A1A',
			dark: '#000000',
		},
		secondaryExtra: {
			75: '#071444',
			50: '#051E50',
			25: '#03295B',
			10: '#013367',
		},
		tertiary: {
			dark: '#2FACE2',
			main: '#32C997',
			light: '#72F324',
			contrastText: '#fff',
		},
		tertiaryExtra: {
			75: '#2DBD8A',
			50: '#32C997',
			25: '#37D4A5',
			10: '#3CE0B3',
		},
		neutral: {
			black: '#212121',
			darkGrey: '#4D4D4D',
			grey: '#717171',
			lightGrey: '#DCE2E6',
			silver: '#F5F7FA',
			white: '#FFFFFF',
		},
		background: {
			default: '#FFF',
			paper: '#F2F2F5',
		},
		gender: {
			male: '#39D2C0',
			woman: '#F51F56',
			other: '#DCE2E6',
		},
		gradients: {
			background: 'linear-gradient(180deg, #020211 0%, #0B0B16 100%)',
			pinkLight:
				'linear-gradient(180deg, rgb(220 226 230 / 50%) 0%, rgb(244 246 247 / 50%) 36.98%, rgb(255 255 255 / 50%) 57.81%, rgb(220 226 230 / 50%) 100%)',
			pink: 'linear-gradient(90deg, #0D0159 0%, #A5007B 50%, #F51F56 100%)',
			pinkOpacity:
				'linear-gradient(90deg, hsl(248.18deg 97.78% 17.65% / 20%) 0%, hsl(315.27deg 100% 32.35% / 20%) 50%, hsl(345deg 91% 54% / 20%) 100%)',
			lgbt: 'linear-gradient(90deg, #FF0018 0%, #FFA52C 19.79%, #FFFF41 38.54%, #008018 58.33%, #0000F9 79.17%, #86007D 100%)',
			tertiary: 'linear-gradient(90deg, #2FACE2 0.15%, #72F223 100%)',
			gold: 'linear-gradient(90deg, #FFD700 0%, #FFA500 50%, #FF8C00 100%)',
		},
		text: {
			primary: '#000000',
			secondary: '#F51F56',
			disabled: alpha('#000000', 0.5),
		},
		success: {
			dark: '#1B6E53',
			main: '#32C997',
			light: '#F1FBF8',
		},
		warning: {
			dark: '#FFA826',
			main: '#FFC670',
			light: '#FFF8EC',
			contrastText: '#000',
		},
		error: {
			dark: '#E01507',
			main: '#FF5A4F',
			light: '#FFF1F0',
		},
		info: {
			dark: '#0792E0',
			main: '#4FBEFF',
			light: '#F0F9FF',
		},
		divider: alpha('#212121', 0.1),
	}
}
