/*
| Developed by Dirupt
| Filename : UsernameWithBadge.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, BoxProps, Chip, Skeleton, Stack, Tooltip, Typography, TypographyProps } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { User } from '@/services/api/modules/user/user.contract';
import { BadgeAdmin, BadgeAmbassador, BadgePartner, BadgePremium } from '@/components/common/svgs/badge';
import { PulseModeIcon } from '@/components/common/svgs/icons/PulseModeIcon';
import { OpenRelationshipIcon } from '@/components/common/svgs/icons/OpenRelationship';
import { UserSituation } from '@/services/api/modules/user-information/user-information.contract';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UsernameWithBadgeProps {
  user?: User;
  boxProps?: BoxProps;
  typographyProps?: TypographyProps;
  showPartnerName?: boolean;
  showSituation?: boolean;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const UsernameWithBadge: React.FC<UsernameWithBadgeProps> = ({
  user,
  boxProps,
  typographyProps,
  showPartnerName = false,
  showSituation = false
}) => {
  const {
    t
  } = useTranslation();

  // Do not show badge if user is not admin, ambassador, partner or if user is not subscription active and is user
  // Also don't show badge if partner is shown
  // ---------------------------------------------------------------------------
  const showBadge = React.useMemo(() => {
    if (!user || showPartnerName) return false;
    return user.isAdmin || user.isAmbassador || user.isPartner || user.isUser && (user.isSubscriptionActive || user.isRedeemed);
  }, [user, showPartnerName]);

  // Get display name based on partner info or user pseudo
  // ---------------------------------------------------------------------------
  const displayName = React.useMemo(() => {
    if (!user) return null;
    if (showPartnerName && user.partner?.name) return user.partner.name;
    return user.userInformation?.pseudo;
  }, [user, showPartnerName]);

  // Render
  // ---------------------------------------------------------------------------
  if (!user) {
    return <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} {...boxProps}>
				<Skeleton animation={'wave'} width={50} height={25} />
				<Skeleton animation={'pulse'} width={25} height={25} />
			</Box>;
  }
  return <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1} {...boxProps} data-sentry-element="Box" data-sentry-component="UsernameWithBadge" data-sentry-source-file="UsernameWithBadge.tsx">
			<Typography {...typographyProps} data-sentry-element="Typography" data-sentry-source-file="UsernameWithBadge.tsx">
				{displayName ? displayName : <Skeleton animation={'wave'} width={50} height={25} />}
			</Typography>
			<Stack direction={'row'} alignItems={'center'} gap={0.5} data-sentry-element="Stack" data-sentry-source-file="UsernameWithBadge.tsx">
				{showBadge ? <Tooltip title={t(`common:userRoles.${user.role}`)}>
						<Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
							{user.isAdmin ? <BadgeAdmin sx={{
            fontSize: 'inherit'
          }} /> : null}
							{user.isAmbassador ? <BadgeAmbassador sx={{
            fontSize: 'inherit'
          }} /> : null}
							{user.isPartner ? <BadgePartner sx={{
            fontSize: 'inherit'
          }} /> : null}
							{user.isUser && (user.isSubscriptionActive || user.isRedeemed) ? <BadgePremium sx={{
            fontSize: 'inherit'
          }} /> : null}
						</Box>
					</Tooltip> : null}
				{user.pulseMode ? <Tooltip title={t('common:common.pulseMode')}>
						<PulseModeIcon sx={{
          fontSize: 'inherit',
          color: 'text.primary'
        }} />
					</Tooltip> : null}
			</Stack>
			{showSituation ? <Chip icon={user?.userInformation?.situation === UserSituation.OPEN_RELATIONSHIP ? <OpenRelationshipIcon /> : undefined} label={user?.userInformation?.situation ? t(`common:situation.${user.userInformation.situation}`) : t('profile/details:tabs.details.situation.noSituation')} size={'small'} color={'primary'} /> : null}
		</Box>;
};