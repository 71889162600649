/*
| Developed by Dirupt
| Filename : DesktopNavButtonItem.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, ClickAwayListener, styled } from '@mui/material';
import { useCycle } from 'framer-motion';
import { MClose, MCloseProps } from '@/components/common/svgs/icons/MClose';
import { DesktopNavContainer } from '@/components/layouts/app/DefaultLayout/DesktopNav/DesktopNavItem';
import { MosaicMenuIcon, MosaicMenuIconProps } from '@/components/common/svgs/icons/MosaicMenuIcon';
import { MosaicMenuNav } from '@/components/layouts/app/DefaultLayout/MosaicMenuNav/MosaicMenuNav';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DesktopNavButtonItemProps {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const MosaicMenuIconStyled = styled(MosaicMenuIcon)<MosaicMenuIconProps>(() => ({
  fill: 'currentColor',
  fontSize: 32,
  '& path': {
    fill: 'currentColor'
  }
}));
const CloseIcon = styled(MClose)<MCloseProps>(() => ({
  fill: 'currentColor',
  fontSize: 32,
  '& path': {
    fill: 'currentColor'
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DesktopNavButtonItem: React.FC<DesktopNavButtonItemProps> = () => {
  const [isOpen, toggleOpen] = useCycle(false, true);

  // Render
  // ----------------------------------------------------------------------------
  return <ClickAwayListener onClickAway={() => {
    if (isOpen) {
      toggleOpen();
    }
  }} data-sentry-element="ClickAwayListener" data-sentry-component="DesktopNavButtonItem" data-sentry-source-file="DesktopNavButtonItem.tsx">
			<Box data-sentry-element="Box" data-sentry-source-file="DesktopNavButtonItem.tsx">
				<MosaicMenuNav isOpen={isOpen} onClose={() => toggleOpen()} data-sentry-element="MosaicMenuNav" data-sentry-source-file="DesktopNavButtonItem.tsx" />
				<DesktopNavContainer onClick={() => toggleOpen()} data-sentry-element="DesktopNavContainer" data-sentry-source-file="DesktopNavButtonItem.tsx">
					{isOpen ? <CloseIcon /> : <MosaicMenuIconStyled />}
				</DesktopNavContainer>
			</Box>
		</ClickAwayListener>;
};