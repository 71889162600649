/*
| Developed by Dirupt
| Filename : MobileNav.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, BoxProps, ClickAwayListener, Grid, GridProps, styled } from '@mui/material';
import { useCycle } from 'framer-motion';
import { MobileNavBackground } from '@/components/layouts/app/DefaultLayout/MobileNav/MobileNavBackground';
import { MHome, MHomeProps } from '@/components/common/svgs/icons/MHome';
import { MRooms, MRoomsProps } from '@/components/common/svgs/icons/MMessage';
import { MBell, MBellProps } from '@/components/common/svgs/icons/MBell';
import { MProfileProps } from '@/components/common/svgs/icons/MProfile';
import { UrlsConfig } from '@/config/urls.config';
import { MobileNavItem } from '@/components/layouts/app/DefaultLayout/MobileNav/MobileNavItem';
import { MobileNavButton } from '@/components/layouts/app/DefaultLayout/MobileNav/MobileNavButton';
import { useMe } from '@/services/api/modules/user/hooks/useMe';
import { useNotificationUnreadCount } from '@/services/api/modules/user-notification/hooks/useNotificationUnreadCount';
import { MediaServices } from '@/services/api/modules/media/media.services';
import { MAvatar, MAvatarProps } from '@/components/common/MAvatar';
import { useUnreadRooms } from '@/services/api/modules/room/hooks/useUnreadRooms';
import { MosaicMenuNav } from '@/components/layouts/app/DefaultLayout/MosaicMenuNav';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type MobileNavItemKey = 'home' | 'rooms' | 'search' | 'notifications' | 'profile';
export type MobileNavLinkItem = {
  type: 'link';
  icon: React.FC<MHomeProps | MRoomsProps | MBellProps | MProfileProps>;
  href: string;
  activeBaseLink: string;
  badge?: number;
};
export type MobileNavButtonItem = {
  type: 'button';
  component: React.ReactNode;
};
export interface MobileNavProps {}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const MOBILE_NAV_HEIGHT = 58;
export const MOBILE_VERY_SMALL_PHONE = 24;
export const MOBILE_SMALL_PHONE = 30;
export const MOBILE_MEDIUM_PHONE = 32;

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const MobileNavStyles = styled(props => <Box component={'nav'} {...props} />)<BoxProps>(() => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  height: MOBILE_NAV_HEIGHT,
  zIndex: 100
}));
const BackgroundContainer = styled(Box)<BoxProps>(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}));
const ButtonContainer = styled(Grid)<GridProps>(({
  theme
}) => ({
  height: MOBILE_NAV_HEIGHT,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: theme.spacing(2),
  zIndex: 100,
  position: 'relative',
  width: '100%',
  '@media (min-width: 360px)': {
    gap: theme.spacing(4)
  },
  '@media (min-width: 430px)': {
    gap: theme.spacing(5)
  }
}));
const HomeIcon = styled(MHome)<MHomeProps>(({
  theme
}) => ({
  fontSize: MOBILE_VERY_SMALL_PHONE,
  '& path': {
    fill: theme.palette.text.primary
  },
  '@media (min-width: 360px)': {
    fontSize: MOBILE_SMALL_PHONE
  },
  '@media (min-width: 430px)': {
    fontSize: MOBILE_MEDIUM_PHONE
  }
}));
const BellIcon = styled(MBell)<MBellProps>(({
  theme
}) => ({
  fontSize: MOBILE_VERY_SMALL_PHONE,
  '& path': {
    fill: theme.palette.text.primary
  },
  '@media (min-width: 360px)': {
    fontSize: MOBILE_SMALL_PHONE
  },
  '@media (min-width: 430px)': {
    fontSize: MOBILE_MEDIUM_PHONE
  }
}));
const RoomsIcon = styled(MRooms)<MRoomsProps>(({
  theme
}) => ({
  fontSize: MOBILE_VERY_SMALL_PHONE,
  '& path': {
    fill: theme.palette.text.primary
  },
  '@media (min-width: 360px)': {
    fontSize: MOBILE_SMALL_PHONE
  },
  '@media (min-width: 430px)': {
    fontSize: MOBILE_MEDIUM_PHONE
  }
}));
const AvatarIcon = styled(MAvatar)<MAvatarProps>(() => ({
  width: MOBILE_VERY_SMALL_PHONE,
  height: MOBILE_VERY_SMALL_PHONE,
  '@media (min-width: 360px)': {
    width: MOBILE_SMALL_PHONE,
    height: MOBILE_SMALL_PHONE
  },
  '@media (min-width: 430px)': {
    width: MOBILE_MEDIUM_PHONE,
    height: MOBILE_MEDIUM_PHONE
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MobileNav: React.FC<MobileNavProps> = () => {
  const {
    data: user
  } = useMe();
  const [isOpen, toggleOpen] = useCycle(false, true);
  const unreadNotifications = useNotificationUnreadCount();
  const unreadMessages = useUnreadRooms();

  // Nav items
  // ----------------------------------------------------------------------------
  const navItems = React.useMemo<Record<MobileNavItemKey, MobileNavLinkItem | MobileNavButtonItem>>(() => {
    return {
      home: {
        type: 'link',
        icon: props => <HomeIcon {...props} />,
        href: UrlsConfig.feed,
        activeBaseLink: UrlsConfig.feed
      },
      rooms: {
        type: 'link',
        icon: props => <RoomsIcon {...props} />,
        href: UrlsConfig.rooms.index,
        activeBaseLink: UrlsConfig.rooms.index,
        badge: unreadMessages?.data ?? 0
      },
      search: {
        type: 'button',
        component: <MobileNavButton isOpen={isOpen} toggleOpen={toggleOpen} />
      },
      notifications: {
        type: 'link',
        icon: props => <BellIcon {...props} />,
        href: UrlsConfig.notifications.index,
        activeBaseLink: UrlsConfig.notifications.index,
        badge: unreadNotifications?.data ?? 0
      },
      profile: {
        type: 'link',
        icon: () => {
          return user?.userInformation?.avatarUrl ? <AvatarIcon src={MediaServices.getAvatarFromUser(user, {
            w: 32
          })} isPremium={user?.isSubscriptionActive} /> : <AvatarIcon src={'/assets/images/avatar/senskle-default.jpg'} />;
        },
        href: UrlsConfig.account.index,
        activeBaseLink: UrlsConfig.account.index
        // href: user?.id ? UrlsConfig.profile.details(user?.id) : UrlsConfig.profile.index,
        // activeBaseLink: user?.id ? UrlsConfig.profile.details(user?.id) : UrlsConfig.profile.index,
      }
    };
  }, [unreadMessages?.data, unreadNotifications?.data, user, isOpen, toggleOpen]);

  // Render
  // ----------------------------------------------------------------------------
  return <ClickAwayListener onClickAway={() => {
    if (isOpen) {
      toggleOpen();
    }
  }} data-sentry-element="ClickAwayListener" data-sentry-component="MobileNav" data-sentry-source-file="MobileNav.tsx">
			<MobileNavStyles data-sentry-element="MobileNavStyles" data-sentry-source-file="MobileNav.tsx">
				<MosaicMenuNav isOpen={isOpen} onClose={() => toggleOpen()} data-sentry-element="MosaicMenuNav" data-sentry-source-file="MobileNav.tsx" />
				<BackgroundContainer data-sentry-element="BackgroundContainer" data-sentry-source-file="MobileNav.tsx">
					<MobileNavBackground data-sentry-element="MobileNavBackground" data-sentry-source-file="MobileNav.tsx" />
				</BackgroundContainer>
				<ButtonContainer container data-sentry-element="ButtonContainer" data-sentry-source-file="MobileNav.tsx">
					{Object.entries(navItems).map(([key, item]) => item.type === 'button' ? <Grid item key={key} width={76} height={MOBILE_NAV_HEIGHT} textAlign={'center'} position={'relative'}>
								{item.component}
							</Grid> : <MobileNavItem key={key} {...item} />)}
				</ButtonContainer>
			</MobileNavStyles>
		</ClickAwayListener>;
};