/*
| Developed by Dirupt
| Filename : DesktopBlackHeader.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React, { SVGProps } from 'react';
import { styled } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DesktopBlackHeaderProps extends SVGProps<SVGSVGElement> {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const Svg = styled('svg')(() => ({
  width: '100%',
  height: 'fit-content'
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DesktopBackgroundHeader: React.FC<DesktopBlackHeaderProps> = props => {
  const theme = useTheme();
  return <Svg viewBox="0 0 1440 82" {...props} data-sentry-element="Svg" data-sentry-component="DesktopBackgroundHeader" data-sentry-source-file="DesktopBackgroundHeader.tsx">
			<path d="M1440 0H0V70.9886C36 74.5028 109 81.2971 222.5 81.2971C297.383 81.2971 367.475 78.8278 439.049 76.3062C513.714 73.6758 589.99 70.9886 675 70.9886C714.704 70.9886 771.099 71.9068 833.131 72.9168C939.833 74.6542 1063.21 76.6631 1147 74.7371C1238.91 72.6244 1351.49 62.8533 1414.67 57.3697C1424.36 56.5289 1432.89 55.7889 1440 55.1928V0Z" fill="url(#paint0_linear_497_12344)" data-sentry-element="path" data-sentry-source-file="DesktopBackgroundHeader.tsx" />
			<defs data-sentry-element="defs" data-sentry-source-file="DesktopBackgroundHeader.tsx">
				<linearGradient id="paint0_linear_497_12344" x1="0" y1="41" x2="1440" y2="41" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="DesktopBackgroundHeader.tsx">
					<stop offset="0" stopColor={theme.palette.mode === 'dark' ? '#DCE2E6' : '#000000'} data-sentry-element="stop" data-sentry-source-file="DesktopBackgroundHeader.tsx" />
					<stop offset="0.5" stopColor={theme.palette.mode === 'dark' ? '#FFFFFF' : '#333333'} data-sentry-element="stop" data-sentry-source-file="DesktopBackgroundHeader.tsx" />
					<stop offset="1" stopColor={theme.palette.mode === 'dark' ? '#DCE2E6' : '#000000'} data-sentry-element="stop" data-sentry-source-file="DesktopBackgroundHeader.tsx" />
				</linearGradient>
			</defs>
		</Svg>;
};