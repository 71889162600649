/*
| Developed by Dirupt
| Filename : MosaicMenuIcon.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MosaicMenuIconProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MosaicMenuIcon: React.FC<MosaicMenuIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="MosaicMenuIcon" data-sentry-source-file="MosaicMenuIcon.tsx">
			<svg viewBox="0 0 32 32" data-sentry-element="svg" data-sentry-source-file="MosaicMenuIcon.tsx">
				<g clipPath="url(#clip0_857_21869)" data-sentry-element="g" data-sentry-source-file="MosaicMenuIcon.tsx">
					<path d="M0.842132 18.5264C0.378948 18.5264 0 18.9053 0 19.3684C0 19.8316 0.378948 20.2106 0.842132 20.2106C1.30532 20.2106 1.68426 19.8316 1.68426 19.3684C1.68419 18.9053 1.30524 18.5264 0.842132 18.5264Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M0.842132 11.7896C0.378948 11.7896 0 12.1685 0 12.6317C0 13.0949 0.378948 13.4738 0.842132 13.4738C1.30532 13.4738 1.68426 13.0949 1.68426 12.6317C1.68419 12.1685 1.30524 11.7896 0.842132 11.7896Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M12.6316 30.3159C12.1684 30.3159 11.7894 30.6949 11.7894 31.158C11.7894 31.6212 12.1684 32.0002 12.6316 32.0002C13.0947 32.0002 13.4737 31.6212 13.4737 31.158C13.4737 30.6949 13.0947 30.3159 12.6316 30.3159Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M5.89476 4.21045C4.96847 4.21045 4.21057 4.96834 4.21057 5.89463C4.21057 6.82092 4.96839 7.5789 5.89476 7.5789C6.82113 7.5789 7.57894 6.821 7.57894 5.89471C7.57894 4.96842 6.82105 4.21045 5.89476 4.21045Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M5.89476 10.9475C4.96847 10.9475 4.21057 11.7054 4.21057 12.6317C4.21057 13.558 4.96847 14.3159 5.89476 14.3159C6.82105 14.3159 7.57894 13.558 7.57894 12.6317C7.57894 11.7054 6.82105 10.9475 5.89476 10.9475Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M5.89476 17.6843C4.96847 17.6843 4.21057 18.4422 4.21057 19.3685C4.21057 20.2948 4.96847 21.0527 5.89476 21.0527C6.82105 21.0527 7.57894 20.2949 7.57894 19.3685C7.57894 18.4422 6.82105 17.6843 5.89476 17.6843Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M31.1579 13.4738C31.6211 13.4738 32.0001 13.0949 32.0001 12.6317C32.0001 12.1685 31.6211 11.7896 31.1579 11.7896C30.6947 11.7896 30.3158 12.1685 30.3158 12.6317C30.3158 13.0949 30.6947 13.4738 31.1579 13.4738Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M19.3684 1.68419C19.8316 1.68419 20.2105 1.30524 20.2105 0.842053C20.2105 0.378948 19.8316 0 19.3684 0C18.9052 0 18.5262 0.378948 18.5262 0.842132C18.5263 1.30524 18.9053 1.68419 19.3684 1.68419Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M12.6316 7.57906C13.5579 7.57906 14.3158 6.82117 14.3158 5.89488C14.3158 4.96859 13.5579 4.21069 12.6316 4.21069C11.7053 4.21069 10.9474 4.96859 10.9474 5.89488C10.9474 6.82117 11.7053 7.57906 12.6316 7.57906Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M12.6316 1.68419C13.0947 1.68419 13.4737 1.30524 13.4737 0.842053C13.4737 0.378948 13.0947 0 12.6316 0C12.1684 0 11.7894 0.378948 11.7894 0.842132C11.7894 1.30524 12.1684 1.68419 12.6316 1.68419Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M19.3684 7.57906C20.2947 7.57906 21.0526 6.82117 21.0526 5.89488C21.0526 4.96859 20.2947 4.21069 19.3684 4.21069C18.4421 4.21069 17.6842 4.96859 17.6842 5.89488C17.6842 6.82117 18.4421 7.57906 19.3684 7.57906Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M5.89476 24.4211C4.96847 24.4211 4.21057 25.179 4.21057 26.1053C4.21057 27.0316 4.96847 27.7895 5.89476 27.7895C6.82105 27.7895 7.57894 27.0317 7.57894 26.1054C7.57894 25.179 6.82105 24.4211 5.89476 24.4211Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M19.3684 10.1052C17.9704 10.1052 16.842 11.2336 16.842 12.6315C16.842 14.0295 17.9704 15.1579 19.3684 15.1579C20.7663 15.1579 21.8947 14.0295 21.8947 12.6315C21.8947 11.2336 20.7663 10.1052 19.3684 10.1052Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M26.1053 17.6843C25.179 17.6843 24.4211 18.4422 24.4211 19.3685C24.4211 20.2948 25.179 21.0527 26.1053 21.0527C27.0316 21.0527 27.7895 20.2948 27.7895 19.3685C27.7895 18.4422 27.0316 17.6843 26.1053 17.6843Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M26.1053 24.4211C25.179 24.4211 24.4211 25.179 24.4211 26.1053C24.4211 27.0316 25.179 27.7895 26.1053 27.7895C27.0316 27.7896 27.7895 27.0317 27.7895 26.1054C27.7895 25.179 27.0316 24.4211 26.1053 24.4211Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M26.1053 10.9475C25.179 10.9475 24.4211 11.7054 24.4211 12.6317C24.4211 13.558 25.179 14.3159 26.1053 14.3159C27.0316 14.3159 27.7895 13.558 27.7895 12.6317C27.7895 11.7054 27.0316 10.9475 26.1053 10.9475Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M31.1579 18.5264C30.6947 18.5264 30.3158 18.9053 30.3158 19.3685C30.3158 19.8316 30.6947 20.2106 31.1579 20.2106C31.6211 20.2106 32.0001 19.8316 32.0001 19.3684C32 18.9053 31.621 18.5264 31.1579 18.5264Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M26.1053 4.21045C25.179 4.21045 24.4211 4.96834 24.4211 5.89463C24.4211 6.82092 25.179 7.5789 26.1053 7.5789C27.0316 7.5789 27.7895 6.821 27.7895 5.89471C27.7895 4.96842 27.0316 4.21045 26.1053 4.21045Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M12.6315 16.842C11.2336 16.842 10.1052 17.9704 10.1052 19.3684C10.1052 20.7663 11.2336 21.8947 12.6315 21.8947C14.0295 21.8947 15.1579 20.7663 15.1579 19.3684C15.1579 17.9704 14.0295 16.842 12.6315 16.842Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M12.6316 24.4211C11.7053 24.4211 10.9474 25.179 10.9474 26.1053C10.9474 27.0316 11.7053 27.7895 12.6316 27.7895C13.5579 27.7895 14.3158 27.0316 14.3158 26.1053C14.3158 25.179 13.5579 24.4211 12.6316 24.4211Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M12.5263 10C11.1284 10 10 11.1284 10 12.5263C10 13.9242 11.1284 15.0526 12.5263 15.0526C13.9242 15.0526 15.0526 13.9242 15.0526 12.5263C15.0526 11.1284 13.9242 10 12.5263 10Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M19.3684 24.4211C18.4421 24.4211 17.6842 25.179 17.6842 26.1053C17.6842 27.0317 18.4421 27.7896 19.3684 27.7896C20.2947 27.7896 21.0526 27.0317 21.0526 26.1054C21.0527 25.179 20.2948 24.4211 19.3684 24.4211Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M19.3684 30.3159C18.9052 30.3159 18.5262 30.6949 18.5262 31.158C18.5262 31.6212 18.9052 32.0002 19.3684 32.0002C19.8316 32.0002 20.2105 31.6212 20.2105 31.158C20.2105 30.6949 19.8316 30.3159 19.3684 30.3159Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
					<path d="M19.3684 16.842C17.9704 16.842 16.842 17.9704 16.842 19.3684C16.842 20.7663 17.9704 21.8947 19.3684 21.8947C20.7663 21.8947 21.8947 20.7663 21.8947 19.3684C21.8947 17.9704 20.7663 16.842 19.3684 16.842Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="MosaicMenuIcon.tsx" />
				</g>
				<defs data-sentry-element="defs" data-sentry-source-file="MosaicMenuIcon.tsx">
					<clipPath id="clip0_857_21869" data-sentry-element="clipPath" data-sentry-source-file="MosaicMenuIcon.tsx">
						<rect width="32" height="32" fill="currentColor" data-sentry-element="rect" data-sentry-source-file="MosaicMenuIcon.tsx" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>;
};