/*
| Developed by Dirupt
| Filename : UploadField.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Box, FormControl, FormHelperText, FormLabel, Typography } from '@mui/material';
import { Accept, DropzoneProps, useDropzone } from 'react-dropzone';
import { useField } from 'formik';
import _ from 'lodash';
import useTranslation from 'next-translate/useTranslation';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UploadInputProps {
  name: string;
  label?: string;
  accept?: Accept;
  maxFiles?: number;
  maxSize?: number;
  helperText?: string;
  dropzoneProps?: Omit<DropzoneProps, 'accept' | 'maxFiles' | 'maxSize'>;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const UploadInput: React.FC<UploadInputProps> = ({
  name,
  label,
  accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/webp': ['.webp'],
    'image/heic': ['.heic'],
    'image/heif': ['.heif'],
    'image/avif': ['.avif'],
    'image/tiff': ['.tiff']
  },
  maxFiles = 1,
  maxSize = 10 * 1024 * 1024,
  // 10MB
  helperText,
  dropzoneProps
}) => {
  const {
    t
  } = useTranslation();
  const [, meta, helpers] = useField(name);
  const {
    error,
    showError
  } = React.useMemo(() => {
    const showError = meta.touched;
    const error = typeof meta.error === 'string' ? meta.error : _.get(meta.error, name);
    return {
      error,
      showError
    };
  }, [meta.error, meta.touched, name]);

  // Methods
  // ----------------------------------------------------------------------------
  const onDrop = React.useCallback((acceptedFiles: Array<File>) => {
    // If multiple files are allowed, we set all of them, otherwise just the first one
    const filesToSet = maxFiles > 1 ? acceptedFiles : [acceptedFiles[0]];
    void helpers.setValue(filesToSet);
  }, [helpers, maxFiles]);

  // Dropzone configuration
  // ----------------------------------------------------------------------------
  const {
    getRootProps,
    getInputProps,
    isDragActive
  } = useDropzone({
    onDrop,
    accept,
    maxFiles,
    maxSize,
    ...dropzoneProps
  });

  // Render
  // ----------------------------------------------------------------------------
  return <FormControl error={showError ? Boolean(error) : undefined} data-sentry-element="FormControl" data-sentry-component="UploadInput" data-sentry-source-file="UploadField.tsx">
			{label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
			<Box {...getRootProps()} sx={{
      border: '1px dashed grey',
      padding: '20px',
      textAlign: 'center'
    }} data-sentry-element="Box" data-sentry-source-file="UploadField.tsx">
				<input {...getInputProps()} name={name} />
				{isDragActive ? <Typography>{t('common:form.upload.dragHere.multiple')}</Typography> : <Typography>{t('common:form.upload.dragAndDrop.multiple')}</Typography>}
			</Box>
			{showError && error || helperText ? <FormHelperText>{error || helperText}</FormHelperText> : null}
		</FormControl>;
};