/*
| Developed by Dirupt
| Filename : user-information-information.contract.ts
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

/*
|--------------------------------------------------------------------------
| Enums
|--------------------------------------------------------------------------
*/
export enum UserSituation {
	ALONE = 'ALONE',
	COUPLE = 'COUPLE',
	OPEN_RELATIONSHIP = 'OPEN_RELATIONSHIP',
	// FOUR_WAY_RELATIONSHIP = '4-way-relationship',
	// FIVE_WAY_RELATIONSHIP = '5-way-relationship',
}

export const MASK_SIZES: Record<UserSituation, number> = {
	[UserSituation.ALONE]: 40,
	[UserSituation.COUPLE]: 40,
	[UserSituation.OPEN_RELATIONSHIP]: 40,
}

export const MASK_NUMBER: Record<UserSituation, number> = {
	[UserSituation.ALONE]: 1,
	[UserSituation.COUPLE]: 2,
	[UserSituation.OPEN_RELATIONSHIP]: 3,
}

export enum UserInformationHowHearAboutUs {
	ADVERTISING = 'ADVERTISING',
	AMBASSADOR = 'AMBASSADOR',
	EVENT = 'EVENT',
	FRIEND = 'FRIEND',
	SEARCH_ENGINE = 'SEARCH_ENGINE',
	SOCIAL_MEDIA = 'SOCIAL_MEDIA',
	OTHER = 'OTHER',
}

/*
|--------------------------------------------------------------------------
| User
|--------------------------------------------------------------------------
*/
export type UserInformation = {
	// Fields
	id: string
	pseudo: string
	situation: UserSituation
	description?: string
	country?: string
	city?: string
	region?: string
	location?: [number, number] // [latitude, longitude]
	avatarId?: string
	howHearAboutUs?: UserInformationHowHearAboutUs
	// Timestamps
	createdAt: string
	updatedAt: string
	// Computed
	avatarUrl: string
	completed: boolean
	// Relations
	userId: string
}
