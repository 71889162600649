/*
| Developed by Dirupt
| Filename : OpenRelationshipIcon.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface OpenRelationshipIconProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const OpenRelationshipIcon: React.FC<OpenRelationshipIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="OpenRelationshipIcon" data-sentry-source-file="OpenRelationshipIcon.tsx">
			<svg viewBox="0 0 20 10" data-sentry-element="svg" data-sentry-source-file="OpenRelationshipIcon.tsx">
				<path d="M18.3512 2.1142C16.5989 0.362009 13.7478 0.362084 11.9956 2.1142L6.88753 7.22222C6.35536 7.75439 5.65636 8.02047 4.95728 8.02047C4.25824 8.02047 3.55923 7.75439 3.02703 7.22222C1.9627 6.15789 1.9627 4.4261 3.02703 3.36173C4.09144 2.29736 5.82327 2.29747 6.88753 3.36173L8.02211 4.49631L9.26967 3.24875L8.13509 2.11417C6.38285 0.362047 3.53178 0.361972 1.77951 2.11417C0.0272736 3.86644 0.0272736 6.71751 1.77951 8.46975C3.53182 10.222 6.38285 10.2219 8.13509 8.46975L13.2431 3.36169C14.3074 2.29747 16.0393 2.29732 17.1036 3.36169C18.168 4.42606 18.168 6.15785 17.1036 7.22218C16.5715 7.75431 15.8723 8.02043 15.1734 8.02043C14.4742 8.02043 13.7754 7.75439 13.2431 7.22218L12.1086 6.0876L10.861 7.33516L11.9956 8.46975C13.7479 10.2219 16.5989 10.222 18.3512 8.46975C20.1034 6.71751 20.1034 3.86648 18.3512 2.1142Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="OpenRelationshipIcon.tsx" />
			</svg>
		</SvgIcon>;
};