/*
| Developed by Dirupt
| Filename : PulseModeIcon.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PulseModeIconProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PulseModeIcon: React.FC<PulseModeIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="PulseModeIcon" data-sentry-source-file="PulseModeIcon.tsx">
			<svg viewBox="0 0 25 25" data-sentry-element="svg" data-sentry-source-file="PulseModeIcon.tsx">
				<path d="M22.5 12a10.516 10.516 0 1 1-4.837-8.835c-.19.167-.35.367-.473.589a9.767 9.767 0 1 0 3.056 3.056c.222-.122.422-.282.589-.472A10.415 10.415 0 0 1 22.5 12ZM3.938 12a8.058 8.058 0 0 0 11.947 7.061 2.2 2.2 0 0 1-.454-.607 7.307 7.307 0 0 1-9.885-9.885 2.2 2.2 0 0 1-.607-.454A7.994 7.994 0 0 0 3.938 12Zm16.125 0A8.057 8.057 0 0 0 8.114 4.939c.185.175.339.38.454.607a7.307 7.307 0 0 1 9.885 9.885 2.2 2.2 0 0 1 .607.454A7.993 7.993 0 0 0 20.063 12Zm-2.438 0a5.624 5.624 0 1 0-10.86 2.059c.188-.177.407-.32.645-.42a4.88 4.88 0 1 1 2.951 2.951c-.1.238-.243.457-.42.645A5.625 5.625 0 0 0 17.625 12Zm1.534-5.659a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Zm-1.721 12.596a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM8.063 6.563a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Zm-1.275 9.15a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0ZM12 15.375c1.68-.754 2.914-1.999 3.356-2.944A2.158 2.158 0 0 0 12 9.89a2.158 2.158 0 0 0-3.356 2.542c.442.945 1.676 2.187 3.356 2.944Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="PulseModeIcon.tsx" />
			</svg>
		</SvgIcon>;
};