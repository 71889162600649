/*
| Developed by Dirupt
| Filename : FollowIcon.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface FollowIconProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const FollowIcon: React.FC<FollowIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="FollowIcon" data-sentry-source-file="FollowIcon.tsx">
			<svg viewBox="0 0 32 32" data-sentry-element="svg" data-sentry-source-file="FollowIcon.tsx">
				<g clipPath="url(#clip0_807_14114)" data-sentry-element="g" data-sentry-source-file="FollowIcon.tsx">
					<path fillRule="evenodd" clipRule="evenodd" d="M14.4999 11.923C12.0359 11.923 10.0384 13.9206 10.0384 16.3846C10.0384 18.8486 12.0359 20.8461 14.4999 20.8461C16.9639 20.8461 18.9615 18.8486 18.9615 16.3846C18.9615 13.9206 16.9639 11.923 14.4999 11.923ZM7.80762 16.3846C7.80762 12.6885 10.8039 9.69226 14.4999 9.69226C18.196 9.69226 21.1922 12.6885 21.1922 16.3846C21.1922 20.0806 18.196 23.0769 14.4999 23.0769C10.8039 23.0769 7.80762 20.0806 7.80762 16.3846Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FollowIcon.tsx" />
					<path fillRule="evenodd" clipRule="evenodd" d="M14.5002 23.0761C12.8523 23.0761 11.2367 23.5324 9.83233 24.3944C8.42795 25.2563 7.28972 26.4904 6.54376 27.9597C6.2649 28.509 5.59357 28.7282 5.04429 28.4494C4.49501 28.1705 4.27581 27.4992 4.55466 26.9498C5.48711 25.1132 6.90992 23.5707 8.66541 22.4932C10.4208 21.4156 12.4404 20.8453 14.5002 20.8453C16.56 20.8453 18.5795 21.4156 20.335 22.4932C22.0904 23.5707 23.5132 25.1132 24.4456 26.9498C24.7245 27.4992 24.5053 28.1705 23.9561 28.4494C23.4068 28.7282 22.7354 28.509 22.4566 27.9597C21.7106 26.4904 20.5724 25.2563 19.168 24.3944C17.7636 23.5324 16.148 23.0761 14.5002 23.0761Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FollowIcon.tsx" />
					<path fillRule="evenodd" clipRule="evenodd" d="M16.4269 5.38373C15.7906 5.28066 15.1469 5.2295 14.5022 5.23077H14.5C12.0734 5.23077 9.70128 5.95035 7.68358 7.2985C5.66592 8.64667 4.09334 10.5629 3.16471 12.8048C2.23609 15.0467 1.99311 17.5136 2.46653 19.8936C2.93994 22.2736 4.10846 24.4598 5.82435 26.1757C7.54023 27.8916 9.72638 29.0601 12.1064 29.5334C14.4864 30.0069 16.9533 29.7639 19.1952 28.8353C21.4371 27.9066 23.3534 26.3341 24.7015 24.3164C26.0497 22.2987 26.7692 19.9266 26.7692 17.5V17.4978C26.7705 16.8532 26.7194 16.2094 26.6163 15.5731C26.5178 14.965 26.9308 14.3922 27.539 14.2937C28.1471 14.1952 28.7198 14.6083 28.8183 15.2164C28.9407 15.9718 29.0014 16.7358 29 17.5011C28.9998 20.3685 28.1494 23.1716 26.5563 25.5558C24.9631 27.9403 22.6984 29.7988 20.0489 30.8962C17.3994 31.9938 14.4839 32.2809 11.6712 31.7214C8.8585 31.1619 6.27482 29.7809 4.24696 27.7531C2.2191 25.7252 0.838111 23.1415 0.278623 20.3288C-0.280865 17.5161 0.00627903 14.6007 1.10375 11.9511C2.20122 9.30157 4.05972 7.03697 6.44423 5.44368C8.82849 3.85061 11.6315 3.00021 14.4989 3M29 17.5011V17.5022L27.8846 17.5L29 17.5011Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FollowIcon.tsx" />
					<path d="M31.7444 0.258252C31.4041 -0.0861925 30.8514 -0.0859754 30.5106 0.258252L24.9577 5.87088L22.4896 3.37643C22.1488 3.03198 21.5964 3.03198 21.2556 3.37643C20.9148 3.72087 20.9148 4.27921 21.2556 4.62365L24.3405 7.74161C24.5108 7.91373 24.7341 8 24.9574 8C25.1807 8 25.4042 7.91394 25.5745 7.74161L31.7444 1.50546C32.0852 1.16125 32.0852 0.602675 31.7444 0.258252Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="FollowIcon.tsx" />
				</g>
				<defs data-sentry-element="defs" data-sentry-source-file="FollowIcon.tsx">
					<clipPath id="clip0_807_14114" data-sentry-element="clipPath" data-sentry-source-file="FollowIcon.tsx">
						<rect width="32" height="32" fill="currentColor" data-sentry-element="rect" data-sentry-source-file="FollowIcon.tsx" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>;
};