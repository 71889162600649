/*
| Developed by Dirupt
| Filename : MosaicMenuNav.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Campaign, SupervisorAccount } from '@mui/icons-material';
import { AnimatePresence } from 'framer-motion';
import { MosaicMenuNavCard } from '@/components/layouts/app/DefaultLayout/MosaicMenuNav/MosaicMenuNavCard';
import { SMenuUserIcon } from '@/components/common/svgs/icons/SMenuUserIcon';
import { SMenuPartnerIcon } from '@/components/common/svgs/icons/SMenuPartnerIcon';
import { SMenuEventIcon } from '@/components/common/svgs/icons/SMenuEventIcon';
import { useMe } from '@/services/api/modules/user/hooks/useMe';
import { UrlsConfig } from '@/config/urls.config';
import { PartnerCategory } from '@/services/api/modules/partner/partner.contract';
import { UserRole } from '@/services/api/modules/user/user.contract';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MosaicMenuNavProps {
  isOpen: boolean;
  onClose: () => void;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MosaicMenuNav = ({
  isOpen = false,
  onClose
}: MosaicMenuNavProps) => {
  const {
    t
  } = useTranslation();
  const {
    data: authUser
  } = useMe();

  // Get community translations from user role
  // ---------------------------------------------------------------------------
  const communityTranslations = React.useMemo(() => {
    return {
      title: t(`common:mosaicMenu.community.${authUser?.role.toLowerCase()}.title`),
      subtitle: t(`common:mosaicMenu.community.${authUser?.role.toLowerCase()}.subtitle`),
      href: authUser?.role === UserRole.AMBASSADOR ? UrlsConfig.ambassador.space : authUser?.role === UserRole.PARTNER ? UrlsConfig.partner.space : authUser?.role === UserRole.ADMIN ? UrlsConfig.admin.redeemCodes : UrlsConfig.account.crushes
    };
  }, [authUser?.role, t]);

  // Render
  // ---------------------------------------------------------------------------
  return <AnimatePresence mode="wait" data-sentry-element="AnimatePresence" data-sentry-component="MosaicMenuNav" data-sentry-source-file="MosaicMenuNav.tsx">
			{isOpen ? <React.Fragment>
					<MosaicMenuNavCard title={t('common:mosaicMenu.users.title')} subtitle={t('common:mosaicMenu.users.subtitle')} icon={<SMenuUserIcon />} href={UrlsConfig.directory.users} custom={0} onClose={onClose} backgroundImage={'/assets/images/mosaic-menu/sensky-bg.jpg'} />
					<MosaicMenuNavCard title={t('common:mosaicMenu.events.title')} subtitle={t('common:mosaicMenu.events.subtitle')} icon={<SMenuEventIcon />} href={UrlsConfig.directory.events} custom={1} onClose={onClose} backgroundImage={'/assets/images/mosaic-menu/events-bg.jpg'} />
					<MosaicMenuNavCard title={t('common:mosaicMenu.partners.title')} subtitle={t('common:mosaicMenu.partners.subtitle')} icon={<SMenuPartnerIcon />} href={UrlsConfig.directory.partners([PartnerCategory.ALL])} custom={2} onClose={onClose} backgroundImage={'/assets/images/mosaic-menu/partners-bg.jpg'} />
					<MosaicMenuNavCard title={t('common:mosaicMenu.profile.title')} subtitle={t('common:mosaicMenu.profile.subtitle')} icon={<SupervisorAccount />} href={UrlsConfig.account.index} custom={3} onClose={onClose} backgroundImage={'/assets/images/mosaic-menu/profile-bg.jpg'} />
					<MosaicMenuNavCard title={communityTranslations.title} subtitle={communityTranslations.subtitle} icon={<Campaign />} href={communityTranslations.href} custom={4} onClose={onClose} backgroundImage={'/assets/images/mosaic-menu/community-bg.jpg'} />
				</React.Fragment> : null}
		</AnimatePresence>;
};