/*
| Developed by Dirupt
| Filename : MobileNavButton.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { Fab, FabProps, styled } from '@mui/material';
import { MOBILE_NAV_HEIGHT } from '@/components/layouts/app/DefaultLayout/MobileNav/MobileNav';
import { MClose } from '@/components/common/svgs/icons/MClose';
import { MosaicMenuIcon } from '@/components/common/svgs/icons/MosaicMenuIcon';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MobileNavButtonProps {
  isOpen: boolean;
  toggleOpen: () => void;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const SearchButton = styled(Fab)<FabProps>(() => ({
  position: 'absolute',
  bottom: MOBILE_NAV_HEIGHT / 2,
  left: '50%',
  transform: 'translateX(-50%)',
  '& svg': {
    fontSize: 32
  }
}));

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const MobileNavButton: React.FC<MobileNavButtonProps> = ({
  isOpen,
  toggleOpen
}) => {
  return <SearchButton color={'primary'} aria-label={'toggle menu'} onClick={() => toggleOpen()} data-sentry-element="SearchButton" data-sentry-component="MobileNavButton" data-sentry-source-file="MobileNavButton.tsx">
			{isOpen ? <MClose /> : <MosaicMenuIcon />}
		</SearchButton>;
};