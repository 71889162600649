/*
| Developed by Dirupt
| Filename : MAvatar.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import { Avatar, AvatarProps, Box, BoxProps, Skeleton, styled } from '@mui/material';
import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import { Link } from '@/components/common/MLink';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface MAvatarProps extends AvatarProps {
  isLoading?: boolean;
  href?: string;
  alt?: string;
  isPremium?: boolean;
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const AvatarContainer = styled(Box)<BoxProps & Partial<NextLinkProps> & {
  isPremium?: boolean;
}>(({
  theme,
  isPremium
}) => ({
  padding: 2,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  background: isPremium ? theme.palette.gradients.gold : theme.palette.gradients.pink,
  borderRadius: '50%'
}));
const AvatarStyles = styled(Avatar)(({
  theme
}) => ({
  width: 40,
  height: 40,
  borderStyle: 'solid',
  borderWidth: 3,
  borderColor: theme.palette.background.default
}));

/*
|--------------------------------------------------------------------------
| Component with Skeleton if no src
|--------------------------------------------------------------------------
*/
export const MAvatar: React.FC<MAvatarProps> = ({
  isLoading,
  href,
  alt,
  isPremium,
  ...rest
}) => {
  if (isLoading) {
    return <Skeleton variant={'circular'} width={40} height={40} />;
  }
  const avatar = <AvatarStyles {...rest} alt={alt} />;
  if (href) {
    return <AvatarContainer component={Link} href={href} isPremium={isPremium}>
				{avatar}
			</AvatarContainer>;
  }
  return <AvatarContainer isPremium={isPremium} data-sentry-element="AvatarContainer" data-sentry-component="MAvatar" data-sentry-source-file="MAvatar.tsx">{avatar}</AvatarContainer>;
};