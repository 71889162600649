/*
| Developed by Dirupt
| Filename : SMenuPartnerIcon.tsx
| Author : Philippe DESPLATS (philippe@dirupt.com)
*/

import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SMenuPartnerIconProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SMenuPartnerIcon: React.FC<SMenuPartnerIconProps> = props => {
  return <SvgIcon {...props} inheritViewBox data-sentry-element="SvgIcon" data-sentry-component="SMenuPartnerIcon" data-sentry-source-file="SMenuPartnerIcon.tsx">
			<svg viewBox="0 0 34 34" data-sentry-element="svg" data-sentry-source-file="SMenuPartnerIcon.tsx">
				<path fillRule="evenodd" clipRule="evenodd" d="M5.31934 22.9252C4.70466 22.5942 4.47613 21.8204 4.80317 21.1981C4.80317 21.1981 6.03253 18.8727 6.03253 18.8687C6.35563 18.2585 7.13975 18.0191 7.73867 18.3462C8.34153 18.6733 8.57794 19.463 8.25484 20.0733L7.02154 22.4027C6.69056 23.021 5.93009 23.2523 5.3154 22.9213L5.31934 22.9252Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
				<path fillRule="evenodd" clipRule="evenodd" d="M30.2061 16.7666C30.0051 16.5154 29.7529 16.296 29.4574 16.1245L22.759 12.2594C22.1719 11.9204 21.569 11.7887 20.9543 11.7887C19.7683 11.7887 18.5468 12.2913 17.349 12.7779C15.0794 13.7073 13.0935 14.521 11.2376 12.4708C13.0344 11.3659 14.5711 10.1653 15.8123 8.89694C16.36 8.33852 16.9116 7.85988 17.4593 7.47696C20.0757 5.64615 21.8842 6.39603 23.6377 7.11798C24.9143 7.64449 26.2343 8.19094 27.6134 7.465L30.3519 5.9812L33.8745 15.1791L30.1982 16.7666H30.2061Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
				<path fillRule="evenodd" clipRule="evenodd" d="M29.2959 19.0522C29.6268 18.4459 29.3983 17.6641 28.7915 17.3131L22.0931 13.4481C20.9425 12.782 19.5161 13.3683 17.8612 14.0464C15.3789 15.0635 12.2897 16.3279 9.67729 12.6862C9.56302 12.5307 9.52362 12.3312 9.56302 12.1398C9.60242 11.9483 9.72063 11.7848 9.88612 11.689C11.876 10.5363 13.5466 9.2719 14.8548 7.93568C15.1937 7.58867 15.5404 7.26957 15.8832 6.97839C14.3977 6.29633 13.1408 6.59548 11.8208 6.91059C10.5441 7.21373 9.22416 7.53282 7.88053 6.77896L4.06635 5L0 13.7632C0.0669845 13.7911 0.14185 13.827 0.224595 13.8629C1.36333 14.3814 3.48319 15.3507 3.87328 15.5022C4.86623 15.8931 5.10658 16.8704 5.25237 17.6482C5.55971 17.3211 5.9498 17.0778 6.38717 16.9422C7.05701 16.7387 7.76232 16.8065 8.377 17.1376C8.98774 17.4687 9.43693 18.0231 9.64183 18.7012C9.69305 18.8687 9.72457 19.0402 9.74427 19.2117C10.3314 19.0801 10.9697 19.1479 11.541 19.459C12.1518 19.7901 12.601 20.3445 12.8059 21.0226C12.8177 21.0665 12.8295 21.1104 12.8413 21.1542C12.8847 21.1383 12.9241 21.1263 12.9674 21.1104C13.6373 20.9029 14.3426 20.9747 14.9572 21.3058C15.568 21.6369 16.0172 22.1913 16.2221 22.8654C16.2339 22.9093 16.2457 22.9531 16.2575 22.997C16.2969 22.9811 16.3403 22.9691 16.3836 22.9531C17.0535 22.7497 17.7588 22.8175 18.3735 23.1486C18.6729 23.3121 18.933 23.5275 19.1457 23.7828L22.0852 25.4261C22.6959 25.7652 23.4643 25.5418 23.7992 24.9235C24.1302 24.3133 23.9056 23.5315 23.2948 23.1885L19.1615 20.8311C18.8345 20.6437 18.7202 20.2288 18.9054 19.8978C19.0866 19.5667 19.5004 19.4511 19.8274 19.6385L25.0561 22.5981C25.6669 22.9372 26.4352 22.7098 26.7702 22.0956C27.1011 21.4853 26.8765 20.7075 26.2658 20.3605C26.25 20.3525 22.1325 17.9952 22.1325 17.9952C21.8094 17.8077 21.6912 17.3929 21.8763 17.0618C22.0615 16.7308 22.4713 16.6151 22.7984 16.8026L27.6016 19.5468C28.1887 19.8818 28.9846 19.6345 29.3077 19.0402L29.2959 19.0522Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
				<path fillRule="evenodd" clipRule="evenodd" d="M21.372 28.0626C21.0371 28.6809 20.2687 28.9043 19.658 28.5652L18.7438 28.0547L19.4413 26.7344C19.6146 26.4073 19.7131 26.0563 19.7407 25.6934L20.8676 26.3196H20.8755C21.4863 26.6626 21.7069 27.4404 21.3759 28.0587L21.372 28.0626Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
				<path fillRule="evenodd" clipRule="evenodd" d="M16.3245 28.944C16.0014 29.0438 15.6586 29.0079 15.3631 28.8483C14.7484 28.5172 14.5199 27.7434 14.8469 27.1212C14.8469 27.1212 16.025 24.8915 16.025 24.8875C16.3481 24.2773 17.1283 24.0379 17.7312 24.365C18.3222 24.6841 18.5705 25.4779 18.2474 26.0921L17.0692 28.3258C16.9116 28.6249 16.6476 28.8443 16.3206 28.944H16.3245Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
				<path fillRule="evenodd" clipRule="evenodd" d="M12.7389 27.4124C12.4158 27.5121 12.073 27.4762 11.7774 27.3167C11.1628 26.9856 10.9342 26.2118 11.2613 25.5896L12.6088 23.0408C12.7665 22.7416 13.0304 22.5223 13.3535 22.4225C13.6767 22.3228 14.0195 22.3587 14.315 22.5183C14.6105 22.6778 14.8233 22.9451 14.9218 23.2721C15.0203 23.6032 14.9888 23.9462 14.8311 24.2454L13.4836 26.7981C13.326 27.0973 13.062 27.3167 12.7349 27.4164L12.7389 27.4124Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
				<path fillRule="evenodd" clipRule="evenodd" d="M9.00744 26.1681C9.33054 26.0684 9.59454 25.849 9.75609 25.5499L11.4228 22.3948C11.7459 21.7846 11.5095 20.9948 10.9066 20.6677C10.3235 20.3526 9.51573 20.5919 9.20051 21.1823L7.52984 24.3413C7.2028 24.9635 7.43527 25.7374 8.04602 26.0684C8.34154 26.228 8.6804 26.2599 9.00744 26.1641V26.1681Z" fill="currentColor" data-sentry-element="path" data-sentry-source-file="SMenuPartnerIcon.tsx" />
			</svg>
		</SvgIcon>;
};